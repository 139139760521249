import React from 'react';
import './App.css';
import myFace from './Images/IMG_4083.jpg';

interface Params { pageIndex: number }

export default function HeaderComp(headerInputs: Params) {


  return (
    <nav className="bg-gray-800">

      <div className="TitleOfPage relative flex items-center justify-between">

        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">

          <div className="hidden sm:ml-6 sm:block">
            <div className="flex space-x-4">

              <a href="#intro" className="NavLinks rounded-md bg-gray-900 px-3 py-2  font-medium hover:animate-bounce" aria-current="page">Home</a>
              <a href="#skills" className="NavLinks rounded-md px-3 py-2  font-medium text-gray-300  hover:animate-bounce">Skills</a>
              <a href="#work" className=" NavLinks rounded-md px-3 py-2  font-medium text-gray-300 hover:animate-bounce ">Work</a>
              <a href="#portfolio" className="NavLinks rounded-md px-3 py-2 font-medium text-gray-300 hover:animate-bounce">Portfolio</a>
              <a href="#contact" className="NavLinks rounded-md px-3 py-2  font-medium text-gray-300 hover:animate-bounce ">Contact</a>
            </div>
          </div>
          </div>
          <div className="hidden sm:ml-6 sm:block text-sm float-right grid grid-cols-2  ">
            <div className="h-10 flex">

              <button onClick={() => { window.open("https://www.linkedin.com/in/mason-lumley", "_blank") }} className="hover:animate-bounce w-10 h-10 flex items-center justify-center rounded-lg bg-white shadow-md shadow-gray-200 group transition-all duration-300">
                <svg className="rounded-md transition-all duration-300 group-hover:scale-110" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 72 72" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6975 11C12.6561 11 11 12.6057 11 14.5838V57.4474C11 59.4257 12.6563 61.03 14.6975 61.03H57.3325C59.3747 61.03 61.03 59.4255 61.03 57.4468V14.5838C61.03 12.6057 59.3747 11 57.3325 11H14.6975ZM26.2032 30.345V52.8686H18.7167V30.345H26.2032ZM26.6967 23.3793C26.6967 25.5407 25.0717 27.2703 22.4615 27.2703L22.4609 27.2701H22.4124C19.8998 27.2701 18.2754 25.5405 18.2754 23.3791C18.2754 21.1686 19.9489 19.4873 22.5111 19.4873C25.0717 19.4873 26.6478 21.1686 26.6967 23.3793ZM37.833 52.8686H30.3471L30.3469 52.8694C30.3469 52.8694 30.4452 32.4588 30.3475 30.3458H37.8336V33.5339C38.8288 31.9995 40.6098 29.8169 44.5808 29.8169C49.5062 29.8169 53.1991 33.0363 53.1991 39.9543V52.8686H45.7133V40.8204C45.7133 37.7922 44.6293 35.7269 41.921 35.7269C39.8524 35.7269 38.6206 37.1198 38.0796 38.4653C37.8819 38.9455 37.833 39.6195 37.833 40.2918V52.8686Z" fill="#006699" />
                </svg>
              </button>

              <button onClick={() => { window.open("mailto:lumleymason@gmail.com", "_blank") }} className="hover:animate-bounce w-10 h-10 flex items-center justify-center rounded-lg bg-white shadow-md shadow-gray-200 group transition-all duration-300 ml-5 mr-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
              </button>

            </div>
            {/*
          <a className="h-10 flex hover:animate-bounce" target="_blank" href="https://www.buymeacoffee.com/lumley"><img src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg" alt="Buy me a coffee" />{<span className="footerCoffee">Buy me a coffee</span>}</a>
*/}

        
        </div>
      </div>

      <div className="sm:hidden " >
              <div className="text-sm float-right  ">
            <div className="h-10 flex">

              <button onClick={() => { window.open("https://www.linkedin.com/in/mason-lumley", "_blank") }} className="hover:animate-bounce w-10 h-10 flex items-center justify-center rounded-lg bg-white shadow-md shadow-gray-200 group transition-all duration-300">
                <svg className="rounded-md transition-all duration-300 group-hover:scale-110" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 72 72" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6975 11C12.6561 11 11 12.6057 11 14.5838V57.4474C11 59.4257 12.6563 61.03 14.6975 61.03H57.3325C59.3747 61.03 61.03 59.4255 61.03 57.4468V14.5838C61.03 12.6057 59.3747 11 57.3325 11H14.6975ZM26.2032 30.345V52.8686H18.7167V30.345H26.2032ZM26.6967 23.3793C26.6967 25.5407 25.0717 27.2703 22.4615 27.2703L22.4609 27.2701H22.4124C19.8998 27.2701 18.2754 25.5405 18.2754 23.3791C18.2754 21.1686 19.9489 19.4873 22.5111 19.4873C25.0717 19.4873 26.6478 21.1686 26.6967 23.3793ZM37.833 52.8686H30.3471L30.3469 52.8694C30.3469 52.8694 30.4452 32.4588 30.3475 30.3458H37.8336V33.5339C38.8288 31.9995 40.6098 29.8169 44.5808 29.8169C49.5062 29.8169 53.1991 33.0363 53.1991 39.9543V52.8686H45.7133V40.8204C45.7133 37.7922 44.6293 35.7269 41.921 35.7269C39.8524 35.7269 38.6206 37.1198 38.0796 38.4653C37.8819 38.9455 37.833 39.6195 37.833 40.2918V52.8686Z" fill="#006699" />
                </svg>
              </button>

              <button onClick={() => { window.open("mailto:lumleymason@gmail.com", "_blank") }} className="hover:animate-bounce w-10 h-10 flex items-center justify-center rounded-lg bg-white shadow-md shadow-gray-200 group transition-all duration-300 ml-5 mr-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
              </button>

            </div>
            {/*
          <a className="h-10 flex hover:animate-bounce" target="_blank" href="https://www.buymeacoffee.com/lumley"><img src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg" alt="Buy me a coffee" />{<span className="footerCoffee">Buy me a coffee</span>}</a>
*/}

        
        </div>
                
              </div>

      <div className="sm:hidden pt-14" id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2">

        <a href="#intro" className="rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white" aria-current="page">Home</a>
              <a href="#skills"  className=" rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white" >Skills</a>
              <a href="#work"  className=" rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white" >Work</a>
              <a href="#portfolio"  className=" rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white" >Portfolio</a>
              <a href="#contact"  className=" rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white" >Contact</a>

             



          <div>


          </div>

        </div>
      </div>
    </nav>
  );
}
