
import CGR from '../Images/Cloud_Guest_RecordsSmall.PNG'
import HOA from '../Images/HOA.PNG'
import tentwen from '../Images/1020icon.PNG'
import '../App.css';
import { useIsVisible } from '../utilities/usIsVisible';
import { useRef } from 'react';

export default function PortfolioComp() {

  const projectRef = useRef<any>();
  const projectIsVisible = useIsVisible(projectRef)

  return (
    <div ref={projectRef} className={`m-8 transition-opacity ease-in duration-700 ${projectIsVisible ? "opacity-100" : "opacity-0"}`} id="portfolio">
      <p className="text-2xl mb-4 "><b>My Projects</b> </p>


      <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2 gap-5">



        <div className="rounded overflow-hidden shadow-lg">
          <img className="w-full" src={tentwen} alt="1020" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">Project 1020</div>
            <p className="text-gray-700 text-base">
              <a href="https://project1020.org/">Project1020</a> is a cold weather shelter in Lenexa Kansas. For my senior project I chose to create a CRUD application for this shelter. This shelter previously utilized an excel spreadsheet to track all guest information.
              This technique took too much time and led to innacurrate data collection, not to mention hours of training needed to properly run the check in desk.


              I ended up developing an easy to use web application that simplified the data collection process,
              while also maintaining data standards.


              Most of our volutneers that use this application are not very tech savy and things must be obvious in the application. It took many iterations and months of feedback to get a version that is robust and easy for everyone to use.
              This application utilizes <b>modern security</b> that keeps our guests information safe.
              This shelter has helped to intake over <b>350 unique guests</b> for a total of over <b>3000 visits</b>, over the past two seasons in use.
            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Node</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">JavaScript</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Typescript</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Express.js</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">HTML</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">EJS</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">CSS</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Bootstrap</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Amazon EC2</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">SQLite</span>
          </div>
        </div>

        <div className="rounded overflow-hidden shadow-lg">
          <img className="w-full" src={CGR} alt="Cloud Guest Records" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">Cloud Guest Records</div>
            <p className="text-gray-700 text-base">

              Data entry software that can manage many shelters at once using a centralized portal via web application.
              This was inspired by expanding upon the Project 1020 applicaiton.

              The unique aspect of this app is that it allows an admin to determine what information they want to collect and what information they want to show in their tables.
              Creating a very complex database and MYSQL queries.
              There are also more users along with multiple shelters running on the same servers, this allows users to log into the shelters they work at and all permissions are controlled by the organization admin.
              There are many more features offered but the final major feature is the ability to download complient data in a format that will allow them to submit this data with ease and recieve funding without hours of data manipulation.

            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">MYSQL</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">API's</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">UML</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">React</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Node</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">JavaScript</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Express</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">NPM</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Tailwind</span>
          </div>
        </div>



        <div className="rounded overflow-hidden shadow-lg">
          <img className="w-full" src={HOA} alt="HOA" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">HOA Website</div>
            <p className="text-gray-700 text-base">

              This request was from my Grandmother :).
              The requirments were loose as she did not exactly know what she wanted, so we resorted to many iterations to find what we needed.


              Our goal was to create a safe place that all members of a community could come to see any updates, or access any forms.
              All members of the community should be able to see things like trash pick up times or numbers to call to get your leaky faucet fixed.
              We also decided to add some fun things like a blog and some interesting facts.
              Since it can only be members of a community we had to impliment some authentication paired with admins allowing new users to join, or even inviting new users to join.


            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">React</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Redux</span>
          </div>
        </div>


      </div>


    </div>
  );
}
