
import '../App.css';
import kochind from '../Images/kochLong.PNG'
import ksSeal from '../Images/KstateLong.PNG'
import pecimg from '../Images/PECIMG.svg'
import { useRef } from 'react';
import { useIsVisible } from '../utilities/usIsVisible';


export default function WorkComp() {

  const schoolRef = useRef<any>()
  const schoolIsVisible = useIsVisible(schoolRef);

  const internRef = useRef<any>();
  const internIsVisible = useIsVisible(internRef);

  const kochRef = useRef<any>();
  const kochIsVisible = useIsVisible(kochRef);

  const pecRef = useRef<any>();
  const pecIsVisible = useIsVisible(pecRef);



  return (
    <div className="m-8 " id="work">
      <p className="text-2xl mb-7"><b>My Employment</b> </p>



      <section ref={pecRef} className={`workSection lg:columns-2 lg:gap-0 transition-opacity ease-in duration-700 ${pecIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder lg:columns-2">
          <div className="imgDiv">
            <img alt="Professional Engineering Consultants" src={pecimg}></img>
          </div>
          <div className="TimeLine">

            <a className="hidden md:block">Current</a>
            <a className="smalldiv md:hidden ">May 2024</a>

            <div className=" dotted-line" />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:hidden bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
            </svg>
            <a className="hidden md:block">May 2024</a>
            <a className="smalldiv md:hidden">Current</a>

          </div>
        </div>

        <div className="RightColWork">
          <p className="w-full text-start font-bold">Full-Stack Software Developer</p>
          <ul className="workList">
            <li>
              Lead developer for our enterprise wide web application used for automating many tasks.
              <ul className="workListInside">
                <li>Front end utilizes React.js.Backend utilizes Express.js.</li>
                <li>Both developed in Typescript.</li>
                
              </ul>
            </li>
            <li>
              Utilize many third party APIs to interact with necessary software, while also developing some REST APIs internally.
            </li>
            <li>
              Developed framework for securely handling three legged authentication in all our internally developed software.
              </li>
            <li>
              Contributed to our company being identified as a super user for the Autodesk Platform Services APIs, with one month even being the biggest user in the world.
            </li>
            <li>
              Wrote various applications in C# utilizing visual studio. Primarily some add-ins for the software Revit utilizing WPF, but also some standalone Windows Forms applications.
            </li>
            <li>
              Work with various database software, including but not limited to, SQL, MongoDB, Elastic Search, and GraphQL.
            </li>
            <li>
              Utilization of APIs for AI services, including Azure's OpenAI.
            </li>

          </ul>
        </div>


      </section>


      <section ref={kochRef} className={` workSection lg:columns-2 lg:gap-0 transition-opacity ease-in duration-700 ${kochIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder lg:columns-2">
          <div className="imgDiv">
            <img alt="Koch Industries " src={kochind}></img>
          </div>
          <div className="TimeLine">





            <a className="hidden md:block">May 2024</a>
            <a className="smalldiv md:hidden ">January 2023</a>

            <div className=" dotted-line" />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:hidden bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
            </svg>
            <a className="hidden md:block">January 2023</a>
            <a className="smalldiv md:hidden">May 2024</a>




          </div>
        </div>


        <div className="RightColWork">
          <p className="w-full text-start font-bold">Automation Specialist</p>
          <ul className="workList">
            <li>
              Primarily focused on the automation needs of Koch's legal capability. Including but not limited to,
              the automation of renewing trademarks.
            </li>
            <li>
              Develop automations using UiPath, Power Automate, PowerShell, JavaScript, and many more.

            </li>
            <li>
              Gather requirements for future automations and determine feasibility and timelines.
            </li>
            <li>
              Support and make enhancements to previously developed automations.
            </li>

          </ul>
        </div>
      </section>

      <section ref={internRef} className={` workSection lg:columns-2 lg:gap-0 transition-opacity ease-in duration-700 ${internIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder lg:columns-2">

          <div className="imgDiv">
            <img alt="Koch Industries" src={kochind}></img>
          </div>
          <div className="TimeLine">

 

            <a className="hidden md:block">August 2022</a>
            <a className="smalldiv md:hidden ">May 2022</a>

            <div className=" dotted-line" />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:hidden bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
            </svg>
            <a className="hidden md:block">May 2022</a>
            <a className="smalldiv md:hidden">August 2022</a>




          </div>
        </div>
        <div className="RightColWork">
          <p className="w-full text-start font-bold">Automation Devloper Intern</p>
          <ul className="workList">
            <li>
              Gained experience with Agile scrum methodology.
            </li>
            <li>
              Worked closely with customers to automate repetative business processes, utilizing software development skills and knowledge.
            </li>
            <li>
              Experimenting with OCR software including but not limited to Textract, Tesseract, and ABBYY.
            </li>
            <li>
              Developing programs using PowerShell, UiPath, Visual Studio Code, and many other tools.
            </li>
            <li>
              2022 Koch Innovation Challenge finalist.
            </li>
          </ul>
        </div>

      </section>


      <section ref={schoolRef} className={` workSection lg:columns-2 lg:gap-0 transition-opacity ease-in duration-700 ${schoolIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder lg:columns-2">
          <div className="imgDiv">
            <img alt="Kansas State University " src={ksSeal}></img>
          </div>


          <div className="TimeLine">




            <a className="hidden md:block">December 2022</a>
            <a className="smalldiv md:hidden ">August 2018</a>

            <div className=" dotted-line" />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:hidden bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
            </svg>
            <a className="hidden md:block">August 2018</a>
            <a className="smalldiv md:hidden">December 2022</a>






          </div>

        </div>
        <div className="RightColWork">
          <p className="w-full text-start font-bold">Bachelors of Science in Computer Science</p>
          <ul className="workList">
            <li>
              College of Engineering Dean`s List 2022.
            </li>
            <li>
              Robotics Competition Team Developer.
            </li>


          </ul>
        </div>

      </section>

    </div>
  );
}
